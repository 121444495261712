import styles from "./Footer.module.css";
import logo from "../../images/logo.svg";

import { useNavigate } from "react-router-dom";

const Footer = (props) => {
  const navigate = useNavigate();
  const handleFQA = () => {
    navigate("/FAQ");
  };

  const handleTerms = () => {
    navigate("/Terms");
  };

  const handlePrivacy = () => {
    navigate("/PrivacyPolicy");
  };

  return (
    <div className={styles.Footer}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" className={styles.logoImage} />
        <div className={styles.logoText}>Storywise</div>
      </div>
      <div className={styles.footerLinks}>
        <div className={styles.footerLinkprivacy1}>
          <strong>Need help? {`  `}</strong> You can reach us anytime via{" "}
          <a className={styles.linktomail} href="mailto:info@storywise.ai">
            info@storywise.ai
          </a>
        </div>
        <div className={styles.footerLinkprivacy} onClick={handlePrivacy}>
          Privacy Policy
        </div>
        <div className={styles.footerLinkterms} onClick={handleTerms}>
          Terms of Use
        </div>
        <div className={styles.footerLinkFAQ} onClick={handleFQA}>
          FAQ
        </div>
      </div>
    </div>
  );
};

export default Footer;
