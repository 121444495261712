import React from "react";
import styles from "./TopCover.module.css";
import logo from "../images/logo.svg";
import rightImage from "../images/Frameuncover.svg"; // Replace with your image
import { useNavigate } from "react-router-dom";

const TopCover = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <div className={styles.topCover}>
      <div className={styles.logo} onClick={navigateHome}>
        <img src={logo} alt="logo" className={styles.logoImage} />
        <div className={styles.logoText}>Storywise</div>
      </div>
      {/* <div  className={styles.section}> */}
      {/* <div className={styles.text}>
                Bringing Science to the Art of
                <div className={styles.WalcomePartText2}>
                    Storytelling
                </div>
            </div>
            <div className={styles.imageRight}>
                <img src={rightImage} alt="imageRight" />
            </div>
            </div> */}
    </div>
  );
};

export default TopCover;
