import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import imageContactUs from "../../imageContact.svg";
import { useRecoilState } from "recoil";
import { publisherOrAuthor } from "../../state";

const ContactUs = (props) => {
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [option, setOption] = useRecoilState(publisherOrAuthor);
  const switchOption = () => {
    setOption(option === "publishers" ? "authors" : "publishers");
  };

  const handleonSubmittion = async () => {
    if (!fullName || !email || !message) {
      props.setAlertType("error");
      console.log("Please fill in all the required details");
      props.showSuccessMessage("Please fill in all the required details");
    } else {
      let requestBody;
      if (option === "publishers") {
        requestBody = {
          contact_full_name: fullName,
          contact_company_name: companyName,
          contact_email_address: email,
          contact_msg: message,
          contact_type: option === "publishers" ? "publisher" : "author",
        };
      } else {
        requestBody = {
          contact_full_name: fullName,
          contact_email_address: email,
          contact_msg: message,
          contact_type: option === "publishers" ? "publisher" : "author",
        };
      }

      try {
        const response = await fetch(
          "https://hkopw0f3gf.execute-api.us-east-1.amazonaws.com/dev/getintouch01",
          {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong! SEND TO COMPARE ITEMS");
        }
        // Show success message
        props.setAlertType("success");
        props.showSuccessMessage("Your message has been sent successfully!");
        setFullName("");
        setCompanyName("");
        setEmail("");
        setMessage("");
      } catch (error) {
        alert("Oops! Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.firstPartContainer}>
        <div className={styles.beforeImage}>
          <div className={styles.contactUs}>Contact Us</div>
          {option === "publishers" ? (
            <div className={styles.optionBar}>
              <div
                className={styles.optionNotSelectedHoutoe}
                onClick={switchOption}
              >
                <div className={styles.optionNotSelectedPublisherText}>
                  For Authors
                </div>
              </div>

              <div className={styles.optionSelectedpublishers}>
                <div className={styles.optionSelectedauthorstext}>
                  For Publishers & Agents
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.optionBar}>
              <div className={styles.optionSelectedauthors}>
                <div className={styles.optionSelectedauthorstext}>
                  For Authors
                </div>
              </div>

              <div
                className={styles.optionNotSelectedPublisher}
                onClick={switchOption}
              >
                <div className={styles.optionNotSelectedPublisherText}>
                  For Publishers & Agents
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.theImage}>
          <img src={imageContactUs} alt="Contact Us" className={styles.Image} />
        </div>
      </div>
      {/* <div className={styles.formContainer}> */}
      <div className={styles.formContainer}>
        {option === "publishers" ? (
          <div className={styles.NameSection}>
            <div className={styles.FullNameForm}>
              <input
                type="text"
                className={styles.FullNameLabel}
                placeholder="Enter Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className={styles.FullNameForm}>
              <input
                type="text"
                className={styles.FullNameLabel}
                placeholder="Enter Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            <div className={styles.emailForm}>
              <input
                type="email"
                className={styles.emailLabel}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div className={styles.NameSection}>
            <div className={styles.FullNameFormAuthor}>
              <input
                type="text"
                className={styles.FullNameLabel}
                placeholder="Enter Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className={styles.emailFormAuthor}>
              <input
                type="email"
                className={styles.emailLabel}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className={styles.MessageSection}>
          <div className={styles.MessageForm}>
            <input
              type="text"
              className={styles.MessageForminput}
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className={styles.subbmit} onClick={handleonSubmittion}>
            <div className={styles.submitButton}>Submit</div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ContactUs;
