import styles from "./Terms.module.css";
import UncoverLeft from "../../UI/UncoverLeft";
import Footer from "../landingP/Footer";
import FooterPatern from "../footerPattern/FooterPatern";

const Terms = (props) => {
  const termsData = [
    {
      question: ``,
      answer: (
        <div>
          <p>
            Storywise AI Ltd. (<b>“Storywise”</b> or the <b>“Company”</b>)
            provides a platform (the <b>“Platform”</b>) which allows authors to
            upload, either directly or via publishers or literary agents,
            Manuscripts. The Platform analyzes them and assesses various
            elements of the content for eligibility, professionalism, and
            marketability (<b>“AI Functions”</b>).
          </p>
          <p>
            These Terms and Conditions (the <b>“Terms”</b>) govern the access to
            and use of the Platform. Storywise reserves the right to update
            these Terms from time to time without notice. You are encouraged to
            check these Terms periodically. If you do not agree with these Terms
            please refrain from using the Platform.
          </p>
          <p>
            Storywise’s Privacy Policy (<b>“Privacy Policy”</b>) is located
            here:
            <a href="/PrivacyPolicy">
              https://www.storywise.ai/PrivacyPolicy
            </a>{" "}
            and these Terms must be read in conjunction with the Privacy Policy.
            In the event of any conflict between certain provisions of these
            Terms and the provisions of the Privacy Policy or the Order Form
            (defined below), the provisions of these Terms shall prevail over
            the conflicting provisions of the Privacy Policy and the provisions
            of any Order From shall prevail over the provisions of these Terms
            and the terms of the Privacy Policy.
          </p>
        </div>
      ),
    },

    {
      question: `1. Interpretation`,
      answer: (
        <div>
          <p>
            <strong>1.1 Agreement:</strong> The agreement between Storywise and
            the Customer for the supply of the Services, consisting of the Order
            Form(s) and these Terms.
          </p>
          <p>
            <strong>1.2 Business Day:</strong> any day (other than a Saturday,
            Sunday or public holiday) when banks in London are open for
            business.
          </p>
          <p>
            <strong>1.3 Charges:</strong> the fees set forth in the Order Form
            that are payable by the Customer under the Agreement.
          </p>
          <p>
            <strong>1.4 Customer:</strong> the person or company who purchases
            the Services, as identified in the Order Form.
          </p>
          <p>
            <strong>1.5 Effective Date:</strong> the date specified on the Order
            Form on which the Agreement becomes effective.
          </p>
          <p>
            <strong>1.6 Initial Term:</strong> the 12-month period from the
            Effective Date, or as otherwise detailed in the Order Form.
          </p>
          <p>
            <strong>1.7 Intellectual Property Rights:</strong> all patents,
            rights to inventions, utility models, copyright and related rights,
            trade-marks, service marks, trade, business and domain names, rights
            in trade dress or get-up, rights in goodwill or to sue for passing
            off, unfair competition rights, rights in designs, rights in
            computer software, database right, topography rights, moral rights,
            rights in confidential information (including know-how and trade
            secrets) and any other intellectual property rights on any media
            whether or not currently existing or yet to be invented, in each
            case whether registered or unregistered and including all
            applications and rights to apply for and be granted, renewals or
            extensions of, and rights to claim priority from, such rights and
            all similar or equivalent rights or forms of protection which
            subsist or will subsist now or in the future in any part of the
            world.
          </p>
          <p>
            <strong>1.8 Manuscript:</strong> a novel, biography, autobiography,
            work of nonfiction or other literary work.
          </p>
          <p>
            <strong>1.9 Order Form:</strong> a form, duly completed and signed
            by both parties, detailing the Customer, the Services provided to
            the Customer by Storywise, the Charges, the Effective Date, and any
            other terms relating to the Agreement.
          </p>
          <p>
            <strong>1.10 Renewal Term:</strong> the 12-month period following
            the end of the Initial Term or the end of the previous Renewal Term,
            as applicable.
          </p>
          <p>
            <strong>1.11 Services:</strong> the provision of the Platform, and
            all related set up, services and support, to the Customer.
          </p>
          <p>
            <strong>1.12 Services Start Date:</strong> The first day of the
            month following the date on which Storywise receives a signed Order
            Form.
          </p>
          <p>
            <strong>1.12 Services Start Date:</strong> The first day of the
            month following the date on which Storywise receives a signed Order
            Form.
          </p>
          <p>
            <strong>1.13 Submission:</strong> A Manuscript, together with
            additional information as prepared by an author and as uploaded
            either directly by the author or by the Customer to the Platform.
          </p>
          <p>
            <strong>1.14 Term:</strong> the Initial Term of the Agreement, and
            any Renewal Term.
          </p>
        </div>
      ),
    },

    {
      question: `2. Acceptance of Terms`,
      answer: (
        <div>
          <p>
            <strong>2.1</strong> These Terms govern any use by you of
            Storywise’s Services. The Services are offered subject to your
            acceptance without modification of all of these Terms and all other
            operating rules, policies and procedures that may be published from
            time to time by the Company.
          </p>
          <p>
            <strong>2.2</strong> The Company reserves the right, at its sole
            discretion, to modify or replace any of the terms or conditions
            contained in these Terms at any time. It is your responsibility to
            check these Terms periodically for changes. Your continued use of
            the Services following the posting of any changes to these Terms
            constitutes your acceptance of those changes and the updated Terms.
            If any change to these Terms is not acceptable to you, your sole
            remedy is to cease accessing and otherwise using the Services.
          </p>
        </div>
      ),
    },
    {
      question: `3. Proprietary Rights and Your Use of The Services`,
      answer: (
        <div>
          <p>
            <strong>3.1</strong> The Company is and shall remain the sole and
            exclusive owner of all right, title and interest in and to the
            Services and any other Intellectual Property Rights licensed or
            developed by the Company, including without limitation all patents,
            copyrights, trade secrets, trademarks, and other intellectual
            property and proprietary rights therein including any and all
            extensions, adaptations, additions, applications improvements,
            updates, upgrades, error-corrections or other modifications thereof,
            and any work products thereof (collectively, the <b>“Company IP”</b>
            ). All modifications of the Company IP or derivative works based
            thereon and developed by the Company under these Terms, in whole or
            in part, will be the sole and exclusive property of the Company. For
            the avoidance of doubt, nothing shall restrict the Company from
            using any function, feature or element of the Services or any other
            Company IP for the purpose of providing the Services. No rights are
            granted to you hereunder other than as expressly set forth in these
            Terms.
          </p>
          <p>
            <strong>3.2</strong> The Company hereby grants you, during the Term,
            a non-exclusive, non-transferable, non-sub licensable right to
            access and use the Platform solely for your use, provided you will
            use the Services (i) in compliance with these Terms, and (ii) to the
            extent permitted under all applicable laws and regulations.
            Notwithstanding the foregoing, you shall not, and shall not permit
            anyone else to, directly or indirectly: (a) make any part of the
            Services available to anyone other than you, or use any Services for
            the benefit of anyone other than you, unless expressly agreed
            otherwise by the Company; (b) sell, resell, license, sublicense,
            distribute, make available, rent or lease any Service, commercially
            exploit, or otherwise make the Services available to any third
            party; (c) use any of the Services to store or transmit infringing,
            libellous or otherwise unlawful or tortious material, or to store or
            transmit material in violation of third-party privacy rights or any
            applicable laws; (d) use the Services to store or transmit any code,
            files, scripts, agents or programs intended to do harm, including,
            for example, viruses, worms, time bombs and Trojan horses; (e)
            interfere with or disrupt the integrity or performance of the
            Services or third-party data contained therein; (f) attempt to gain
            unauthorized access to the Services or its related systems or
            networks; (g) permit direct or indirect access to or use of any
            Services in a way that circumvents a contractual usage limit, or use
            any Services to access or use any of Company IP (as defined above)
            except as permitted under these Terms; (h) modify, copy, duplicate,
            imitate, reproduce or create derivative works based on the Services
            or any part, feature, function or user interface thereof; (i) frame
            or mirror any part of any Service; (j) disassemble, reverse
            engineer, or decompile the Services or access it to (1) build a
            competitive product or service, (2) build a product or service using
            similar ideas, features, functions or graphics of the Service, (3)
            copy any ideas, features, functions or graphics of the Service, or
            (k) use the Services in any manner that is unlawful or prohibited or
            in violation of these Terms and/or any applicable law or regulation
            (the foregoing (a) through (k), collectively, the{" "}
            <b>“Prohibited Uses”</b>).
          </p>
          <p>
            <strong>3.3</strong> Company reserves the right, at its sole
            discretion without any liability to you, to disable, suspend or
            terminate your right to use or access the Services in the event the
            Company determines or reasonably suspects that you are, directly or
            indirectly, engaging in any of the Prohibited Uses.
          </p>
          <p>
            <strong>3.4</strong> The trademarks, service marks, and logos of the
            Company (the <b>“Company Trademarks“</b>) used and displayed in
            connection with the Services are registered and/or unregistered
            trademarks or service marks of the Company. Other company product
            and service names used in connection with the Services may be
            trademarks or service marks owned by third parties (the{" "}
            <b>“Third Party’s Trademarks“</b>, and, collectively with the
            Company Trademarks, the
            <b>“Trademarks“</b>). The offering of the Services shall not be
            construed as granting any license or right to use any Trademark
            displayed in connection with the Services without the prior written
            consent of the Company. The Trademarks may not be used to disparage
            the Company or any third party or in any manner (in the Company’s
            sole judgment) that may damage any goodwill in the Trademarks. Use
            of any Trademarks as part of a link to or from any site is
            prohibited unless the Company approves the establishment of such a
            link by prior written consent specific for each such link.
          </p>
          <p>
            <strong>3.5</strong> You hereby grant to Company a worldwide,
            perpetual, irrevocable, royalty-free license to use and incorporate
            into its services any suggestion, enhancement request,
            recommendation, correction or other feedback provided by you
            relating to the operation of Company’s Services.
          </p>
        </div>
      ),
    },
    {
      question: `4. Password And Security`,
      answer: (
        <div>
          <p>
            <strong>4.1</strong> As part of using the Services, you may create a
            password or any kind of additional security method. You are
            responsible for maintaining the confidentiality of the password or
            any kind of additional security method. You are fully responsible
            for all activities that occur on your phone or computer and any
            other device.
          </p>
          <p>
            <strong>4.2</strong> The Company cannot and will not be liable for
            any loss, damage or other liability arising from your failure to
            comply with this section or from any unauthorized access to or use
            of your phone or computer or any other device while using the
            Services.
          </p>
        </div>
      ),
    },
    {
      question: `5. Your Obligations`,
      answer: (
        <div>
          <p>
            <strong>5.1</strong> You agree to: (a) use the Services only in
            accordance with these Terms and in compliance with applicable laws
            and government regulations and guidelines, including any applicable
            privacy laws; (b) if you represent a company or other corporate
            entity, be responsible for your users’ compliance with these Terms;
            (c) use commercially reasonable efforts to prevent unauthorized
            access to or use of Services and notify the Company promptly of any
            such unauthorized access or use; (d) to the extent required, obtain
            and maintain all necessary licenses, consents, and permissions
            necessary for the Company to perform its obligations under these
            Terms, including the transmission through the Services, of any data
            (including personal data) submitted by you (“Data”); (d) be solely
            liable for problems, conditions, delays, delivery failures and all
            other loss or damage arising from or relating to your network
            connections or telecommunications links; and (e) be responsible for
            the accuracy, quality and legality of your Data, the means by which
            you acquired your Data and your use of the Data together with the
            Services.
          </p>
          <p>
            <strong>5.2</strong> You acknowledge, represent and warrant that:
            (i) Company does not control or monitor, and is not responsible for
            the content of your Data; (ii) the Company reserves the right to
            remove your Data which is deemed to be in violation of any of these
            Terms, at the Company’s sole discretion; (iii) the Company makes no
            representations or warranties as to your Data, and all express,
            implied and statutory warranties in connection with your Data,
            including without limitation with respect to the legality,
            reliability, authenticity, integrity, accuracy, content,
            completeness, availability and quality thereof, are all expressly
            disclaimed to the fullest extent permitted by law.
          </p>
          <p>
            <strong>5.3</strong> By using the Services, you give your consent to
            the Company, and grant to the Company a worldwide, royalty-free, and
            non-exclusive license, to collect your Data, store the Data in the
            Company’s third party’s external servers or cloud services on which
            the Services operates, and to use the Data in order to provide the
            Services.
          </p>
        </div>
      ),
    },
    {
      question: `6. Third-Party Providers`,
      answer: (
        <div>
          <p>
            <strong>6.1</strong> You acknowledge that the Company uses Amazon
            Web Services, Inc. to host your Data or provide other infrastructure
            that helps with the delivery of the Company’s Services (
            <b>“Third-Party Cloud Provider”</b>). You acknowledge and agree that
            Company is not responsible for the availability and performance of
            the Third-Party Cloud Provider, and shall not be held liable for any
            loss or damage (including loss of data and/or loss of profits),
            which may be incurred by you, as a result of the lack of the
            Third-Party Cloud Provider availability or error in their
            performance of the Services.
          </p>
          <p>
            <strong>6.2</strong> You also acknowledge that the Company may
            include in the Services certain third-party software, components,
            cookies and/or libraries that are subject to open source license
            and/or to other third-parties license terms (
            <b>“Third-Party Software”</b>). You acknowledge and agree to the use
            by the Company of such Third-Party Software.
          </p>
          <p>
            <strong>6.3</strong> You acknowledge and agree that your right to
            use such Third-Party Software in connection with the Services, is
            subject to and governed by the terms and conditions of the open
            source or third-party license applicable to such Third-Party
            Software, including, without limitation, any applicable
            acknowledgements, license terms and disclaimers contained therein.
            In the event of a conflict between the provisions of these Terms and
            the terms of such Third-Party Software license, the terms of the
            applicable Third-Party Software licenses shall prevail with regard
            to your use of the relevant Third-Party Software. Other than the
            applicable Third-Party Software component, in no event, will the
            Services (or any part thereof) be deemed to be “open source” or
            “publicly available” software.
          </p>
          <p>
            <strong>6.4</strong> The Services may provide, or third parties may
            provide, links to other Internet websites or resources. Because the
            Company has no control over such websites and resources, you
            acknowledge and agree that the Company is not responsible for the
            availability of such websites or resources, and does not endorse and
            is not responsible or liable for any content, advertising, products,
            services or other materials available from such websites or
            resources. You further acknowledge and agree that the Company shall
            not be responsible or liable for any damage or loss caused or
            alleged to be caused by or in connection with any use of or reliance
            on any such content, advertising, products, services or other
            materials available on or through any such website or resource.
          </p>
        </div>
      ),
    },
    {
      question: `7. Charges and Payment for The Services`,
      answer: (
        <div>
          <p>
            <strong>7.1</strong> In consideration for provision of the Services
            you agree to pay the Company the Charges set forth in the Order
            Form. Storywise shall invoice the Customer monthly, starting after
            the Effective Date, with payment due within one week from the
            invoice date. Unless expressly provided otherwise in the Order Form,
            all Charges and other amounts due: (i) are stated in and are payable
            in USD or GBP, as indicated on the invoice; (ii) are exclusive of
            taxes and similar charges, including sales, usage, excise, VAT, and
            other taxes (to the extent applicable); (iii) shall be paid by via
            by BACS transfer (with wiring charges to be borne by the Customer);
            (iv) are non-cancellable and non-refundable; and (v) are exempt from
            any counterclaim or other right of set-off.
          </p>
          <p>
            <strong>7.2</strong> Any Services fees and other amounts due under
            these Terms which are not paid when due are subject to a finance
            charge of 1.5% per month on any outstanding balance, or the maximum
            permitted by law, whichever is lower, plus all expenses of
            collection and may result in termination of the Services. In
            addition, if you are more than 30 days late in paying an invoice,
            you shall be considered to be in material breach of these Terms and
            without prejudice to any other rights and remedies available to the
            Company under any applicable law, the Company may, at its sole
            discretion and without liability to you: (i) disable, suspend or
            terminate your access to all or part of the Services, and Company
            shall be under no obligation to provide any or all of the Services
            while the invoice(s) concerned remain unpaid; and/or (ii)
            immediately suspend the Services or otherwise terminate these Terms
            and/or any related Order Form. The Company’s right to terminate
            these Terms shall not release you of your payment obligations, as
            the same shall remain in effect together with the Company’s right to
            collect any attorneys’ fees or collections fees as relevant.
          </p>
          <p>
            <strong>7.3</strong> The Company reserves the right to change
            (including increase) its prices (or any part thereof) at any time,
            however any such change will only take effect upon commencement of
            the next Renewal Term, provided, that no change that was made within
            less than 45 days of the next Renewal Term will be applied to such
            Renewal Term.
          </p>
        </div>
      ),
    },
    {
      question: `8. Confidentiality`,
      answer: (
        <div>
          <p>
            <strong>8.1</strong> During the provision of the Services, you and
            the Company may be provided with, given access to, or exposed to,
            Confidential Information of the other party. ‟Confidential
            Information” shall mean any information and data of a proprietary or
            confidential nature, whether in oral, written, graphic,
            machine-readable form, or in any other form, including but not
            limited to proprietary, technical, development, marketing, sales,
            price, operating, performance, cost, know-how, business and process
            information, methods, procedures, data, computer programming
            techniques and computer code, any information regarding suppliers,
            licensors, licensees, partners, affiliates, customers, potential
            customers or others, and all record bearing media containing or
            disclosing such information and techniques, which is disclosed by
            one party to the other party pursuant to these Terms or to which the
            other party is exposed or given access in connection with the
            Agreement, whether or not marked as “Confidential” or similar
            marking. Without derogating from the generality of the foregoing,
            Confidential Information of the Company shall also include the
            Company IP, details of the Services, and the results of any
            performance tests of the Services and any work products of the
            Services, any Outputs from the AI Functions (as defined below) and
            your Confidential Information shall also include your Data.
            “Confidential Information” shall not include any information that:
            (a) is or becomes publicly known other than through any act or
            omission of the receiving party; (b) was in the receiving party’s
            lawful possession before the disclosure, as evidenced by applicable
            documentation; (c) is lawfully disclosed to the receiving party by a
            third-party without restriction on disclosure; (d) is independently
            developed by the receiving party, as evidenced by applicable
            documentation; or (e) is required to be disclosed by any final
            judicial or administrative order or decree or pursuant to any
            applicable law, in which case such party shall provide the other
            with prompt written notice of the existence, terms and circumstances
            of such order or decree so that a protective order or other
            appropriate remedy may be sought and/or compliance with these Terms
            may be waived, and in all cases may furnish only the minimum portion
            of the Confidential Information which is legally required.
          </p>
          <p>
            <strong>8.2</strong> Each party shall hold the other party’s
            Confidential Information in strict confidence, shall not disclose or
            make such Confidential Information available to any third-party and
            shall not use such Confidential Information for any purpose other
            than for performing its obligations under these Terms.
          </p>
          <p>
            <strong>8.3</strong> Each party shall be entitled to disclose the
            other party’s Confidential Information to its officers, directors,
            employees, affiliates and consultants (<b>‟Representatives”</b>), on
            a need to know basis in order to perform its obligations hereunder,
            provided that such Representatives are bound by confidentiality
            obligations of at least the same scope of these Terms and provided
            further that such party shall be responsible for and liable to any
            breach or violation of these Terms by such Representatives.
          </p>
          <p>
            <strong>8.4</strong> Notwithstanding the provisions of this section
            8, the Customer grants Storywise a limited right to use the
            Customer’s name, logo and marks, exclusively for promotional
            purposes during the term of the Agreement.
          </p>
        </div>
      ),
    },
    {
      question: `9. Restrictions On Use of The Website`,
      answer: (
        <div>
          <p>
            <strong>9.1</strong> By using the Platform, you agree not to engage
            in any activity that, in the Company’s sole discretion:
          </p>
          <p>
            <strong>9.1.1</strong> is illegal or violates any law or regulation.
          </p>
          <p>
            <strong>9.1.2</strong> distributes, transmits, syndicates, sells or
            offers to sell or otherwise makes available all or any part of the
            Platform or any content, files, feeds or data from the Company's
            website, whether publicly available or not.
          </p>
          <p>
            <strong>9.1.3</strong> copy, download, or store any content, files
            or data from the Platform or the Company’s website, reproduce,
            download, modify, translate, add to, publish, perform, display,
            disclose, archive, upload, broadcast or sell, sublicense, index or
            exploit any part of the Platform or the Company’s website or the
            content thereon in any medium, either directly or through the use of
            any device, software, internet site, web-based services or other
            means.
          </p>
          <p>
            <strong>9.1.4</strong> mirror, frame, screen scrape or deep link to
            any aspect of content in the Platform or the Company’s website
            through technology or means other than those provided or authorized
            by the Company.
          </p>
          <p>
            <strong>9.1.5</strong> access the Services via any automated system,
            including, without limitation, by “robots,” “spiders,” “offline
            readers,” etc., or take any action that infringes, or which may
            infringe (as determined in the Company’s sole discretion) on any
            right of the Company.
          </p>
          <p>
            <strong>9.1.6</strong> knowingly or recklessly upload invalid data
            or introduce viruses, worms, trojan horses or other malware or
            software agents, whether harmful or not, to the Platform or the
            Company’s website, or tamper with, impair, damage, attack, exploit
            or penetrate the Services, or otherwise attempt to interfere with or
            the Services.
          </p>
          <p>
            <strong>9.1.7</strong> bypass the measures the Company may use to
            prevent or restrict access to or use of the Services, including by
            hacking into secured or non-public areas of the Platform and/or the
            Company’s website.
          </p>
        </div>
      ),
    },
    {
      question: `10. AI Content`,
      answer: (
        <div>
          <p>
            <strong>10.1</strong> When using the Platform, you will upload
            Submissions to the Platform (<b>"Input"</b>) and receive output
            (which may be composed of a log line, synopsis and review) generated
            and returned by the Al Functions based on an analysis of the Input (
            <b>"Output"</b>, and together with Input, <b>"Al Content"</b>).
          </p>
          <p>
            <strong>10.2</strong> By inputting the Input, you acknowledge that
            you have all legal right and title to the Input, and you are
            permitted to input the Input into the Platform without any
            restrictions. If there are any restrictions on your inputting of the
            Input, please refrain from using the Platform. Additionally, by
            using the Platform, you acknowledge and consent to the Input being
            shared with Storywise’s technology partners, such as Amazon Web
            Services (AWS), as well as other service providers as detailed in
            our Privacy Policy, in order to provide you with the AI Functions.
          </p>
          <p>
            <strong>10.3</strong> It is hereby clarified that when you use the
            Platform, the author to whom the Manuscript belongs remains the
            owner of the Manuscript and the publisher/literary agent is the
            owner of any Output.
          </p>
          <p>
            <strong>10.4</strong> Storywise exclusively owns all rights, title,
            and interest in and to the Platform and the Services, including all
            associated Intellectual Property Rights. No license or other rights
            (express or implied) in or to the Platform are granted, assigned,
            licensed, or conveyed to you.
          </p>
        </div>
      ),
    },
    {
      question: `11. Training AI Models`,
      answer: (
        <p>
          Storywise will not conduct training of any AI models on Manuscripts.
          In the future Storywise may allow for the use of: (i) Outputs, and/or
          (ii) any part of the Submission which is not the Manuscript, for model
          training purposes, however this will only be done on a per-publisher
          basis (i.e. Output of one publisher will only be used to train that
          publisher’s model) and this will only be done with notice to the
          relevant publisher and consent by that publisher.
        </p>
      ),
    },
    {
      question: `12. Your Consent and Acknowledgement`,
      answer: (
        <div>
          <p>
            <strong>12.1</strong> Storywise provides the Platform to enhance its
            services, provide personalized experiences and improve Storywise’s
            services. By using the Platform you:
          </p>
          <p>
            <strong>12.1.1</strong> Expressly consent to the use of artificial
            intelligence (AI) features as part of the use of the Platform.
          </p>
          <p>
            <strong>12.1.2</strong> acknowledge and agree that Storywise may
            collect, store, and process the AI Content, which may include
            personal data, as necessary to provide the AI Functions.
          </p>
          <p>
            <strong>12.1.3</strong> Represent and warrant that you have obtained
            all required consents and authorizations as to allow Storywise to
            use the AI Functions for purposes detailed in these Terms, including
            notifying the author (if the submitter of the Manuscript is not the
            author) of the use of the AI Functions, and you have secured all
            rights necessary to grant Storywise the ability to use the AI
            Functions in the manner detailed in these Terms. You have the right
            to withdraw your consent to the use of data by the AI Functions, at
            any time, by stopping to use the Platform.
          </p>
          <p>
            <strong>12.1.4</strong> Agree that you are responsible for any
            Input, as well as Output you generate.
          </p>
          <p>
            <strong>12.1.5</strong> Acknowledge that the Output is generated by
            the AI Functions on a non-exclusive basis and may be provided to
            other users as well. You acknowledge that Storywise cannot guarantee
            that the Outputs will be unique or will not be similar to Outputs of
            other users. You hereby waive any claim with respect to any similar
            or identical Outputs that may be generated by other Storywise users.
          </p>
          <p>
            <strong>12.1.6</strong> Acknowledge that your reliance on any Output
            is done at your own risk. Storywise strongly recommends that you
            carefully evaluate the Output for accuracy and appropriateness for
            your specific use.
          </p>
          <p>
            <strong>12.1.7</strong> Are responsible for ensuring that any AI
            Content: (i) complies at all times with these Terms and the
            Agreement; (ii) does not violate any applicable laws and regulations
            of the jurisdictions which apply to your use of the Platform; (iii)
            does not infringe, violate, or misappropriate any of Storywise’s
            rights or the rights of any third party, including Intellectual
            Property Rights.
          </p>
          <p>
            <strong>12.1.8</strong> Permit Storywise to disclose such content to
            law enforcement or other governmental authorities in response to a
            subpoena or court order, in the event that any AI Content is alleged
            to be unlawful or otherwise in breach of the Agreement.
          </p>
          <p>
            <strong>12.1.9</strong> Give Storywise the right to host the AI
            Content on Storywise’s Platform and use it as necessary to provide
            the Services, comply with applicable laws, enforce Storywise’s
            policies and keep Storywise’s Services safe.
          </p>
          <p>
            <strong>12.1.10</strong> Provide all assistance and cooperation
            necessary for Storywise to provide you with the Services, as
            reasonably required.
          </p>
        </div>
      ),
    },
    {
      question: `13. Restrictions`,
      answer: (
        <p>
          You hereby agree, when using the Platform, not to intentionally
          generate any harmful content. It is prohibited to use the Platform to:
          (i) mislead anyone that the content generated by the AI Functions is
          human-generated; (ii) provide medical, financial or legal advice;
          (iii) generate spam, ransomware, viruses, intentional disinformation
          or deception, or other software, or to intentionally circumvent safety
          filters and functionality or prompt models to act in a manner that
          violates these Terms; (iv) generate or disseminate information to be
          used for the administration of justice or other legal purposes; (v)
          violate the privacy rights or Intellectual Property Rights of others,
          including unlawful tracking, monitoring, and identification; (vi)
          harass, harm, or encourage the harm of individuals or specific groups;
          or to (vii) use the AI Functions for automated decision-making that
          has legal or similarly significant effects on individuals, unless you
          do so with adequate human review and in compliance with applicable
          law, and in such a case you acknowledge you are doing so at your own
          risk.
        </p>
      ),
    },
    {
      question: `14. Term and Termination`,
      answer: (
        <div>
          <p>
            <strong>14.1</strong> 14.1. The Agreement commences on the Effective
            Date and continues for the Initial Term, automatically renewing for
            each Renewal Term, unless terminated in accordance with the terms
            below.
          </p>

          <p>
            <strong>14.2</strong> 14.2. Either party may terminate the Agreement
            with at least 30 days’ notice prior to the end of the Initial Term
            or any Renewal Term (as applicable), which will come into effect at
            the end of the applicable Term.
          </p>

          <p>
            <strong>14.3</strong> Notwithstanding the foregoing, and without
            prejudice to any other rights or remedies to which the Company may
            be entitled under these Terms and/or any applicable law, the
            Company, at its sole discretion may immediately terminate the use of
            the Services by you and terminate any Order Form signed between the
            Company and you and immediately deactivate any further access to the
            Services or suspend the Services, without liability to you if: (a)
            you breached any provision of these Terms or any applicable law or
            regulation, including without limitation your failure to meet the
            data security requirements or your failure to timely pay any amount
            owed to the Company; (b) if you become insolvent, make an assignment
            for the benefit of creditors, file or have filed against it a
            petition in bankruptcy or seeking reorganization, have a receiver
            appointed, or institute any proceedings for the liquidation or
            winding up, and/or (c) if the Company believes, in its sole
            discretion that the use of the Services by you creates a risk for
            the Company and/or for any other third party user of the Services.
          </p>

          <p>
            <strong>14.4</strong> You agree that the Company shall not be liable
            to you or any third party for any termination of your right to use
            or otherwise access the Services.
          </p>

          <p>
            <strong>14.5</strong> Upon termination or expiration of these Terms
            for any reason whatsoever: (a) all rights granted to you under these
            Terms shall immediately terminate; (b) you shall immediately cease
            any use of the Services; (c) all outstanding Services’ fees will
            immediately become due and payable by you; (d) to the greatest
            extent allowed by law, you shall delete and make no further use of
            the Company’s Confidential Information and (e) the Company will
            destroy any of the your Data in its possession (if any) upon your
            written request.
          </p>

          <p>
            <strong>14.6</strong> For the avoidance of doubt, all the provisions
            of these Terms that by their nature should survive termination of
            your right to use the Services shall survive (including, without
            limitation, all limitations on liability, releases, indemnification
            obligations, disclaimers of warranties, and intellectual property
            protections).
          </p>
        </div>
      ),
    },
    {
      question: `15. Disclaimer of Warranties`,
      answer: (
        <div>
          <p>
            <strong>15.1</strong> Storywise is constantly working to improve its
            Services to make them more accurate, reliable, safe, and beneficial.
            Given the nature of artificial intelligence machine learning
            technology and the fact that the Outputs are generated through
            machine learning processes and are not tested, verified and
            endorsed, the AI Functions may, in some situations, result in
            inaccurate Output. The use of the AI Functions is at your sole risk.
            You should independently review and verify all Outputs as to
            appropriateness for any use cases. You acknowledge that Storywise
            has not verified the accuracy of the Output and it does not
            represent Storywise’s views. Storywise makes no warranty or
            guarantee as to the accuracy, completeness, fitness for a specific
            purpose, or reliability of the Output, nor the legal possibility to
            use the Output or whether it infringes any third parties’ rights,
            and shall not have any liability or responsibility arising in any
            way regarding the Output or any omissions or errors contained in the
            Output. Storywise recommends that you obtain professional and
            independent advice before you act on any advice contained in the
            Output, rely on the accuracy of the Output, or use of the Output, in
            any way.
          </p>

          <p>
            <strong>15.2</strong> Storywise reserves the right to immediately,
            without reason and without notice, discontinue any or all AI
            Functions as deemed necessary, and you acknowledge and agree that
            you have no claims against Storywise should such action be taken.
          </p>

          <p>
            <strong>15.3</strong> You acknowledge and agree that Storywise is
            not responsible for any activities or legal consequences of your use
            of the AI Functions or its Outputs, including in case that a third
            party has a claim against you following the usage of such AI
            Functions.
          </p>

          <p>
            <strong>15.4</strong> The AI Functions are provided on an “as is”
            and “as available” basis. Storywise hereby expressly disclaims all
            warranties of any kind, express or implied, including, but not
            limited to, implied warranties of merchantability, title,
            non-infringement and fitness for a particular purpose, as well as
            any warranties regarding security, suitability, reliability,
            availability, timeliness, accuracy and performance of the AI
            functions. Storywise makes no warranty that: (i) the AI Functions
            will meet your requirements, (ii) the AI Functions will be
            uninterrupted, timely, secure, or error-free, (iii) the results
            and/or output that may be obtained from the use of the AI Functions
            will be accurate or reliable, or (iv) any errors in the AI Functions
            will be corrected. The foregoing disclaimers shall not apply to the
            extent prohibited by applicable law. In addition, Storywise does not
            warrant or guarantee the accuracy of the results generated by the AI
            Functions. You remain responsible for making your own business
            decisions and for determining the appropriateness of the AI
            functions for your unique use case and needs.
          </p>
        </div>
      ),
    },
    {
      question: `16. Limitation of Liability`,
      answer: (
        <p>
          In no event shall Storywise be liable, whether in tort, contract,
          misrepresentation, restitution or otherwise, for any loss of profits,
          loss of business, depletion of goodwill and/or similar losses, pure
          economic loss, or for any special, indirect punitive, incidental,
          consequential, exemplary or other similar loss, costs, damages,
          charges or expenses, arising out of or in any way connected with the
          use of, misuse, inability to use, or the reliance upon, the AI
          Functions and/or otherwise in connection with these Terms; and in no
          event shall Storywise’s total aggregate liability, in contract, tort,
          misrepresentation, restitution or otherwise, arising out of or in any
          way connected with the use of, misuse, inability to use, or the
          reliance upon, the AI Functions and/or otherwise in connection with
          the Agreement, exceed the total charges paid by you for the AI
          Functions during the 12 month period preceding the date in which the
          claim leading to liability arose.
        </p>
      ),
    },
    {
      question: `17. Indemnity`,
      answer: (
        <p>
          You agree to defend, indemnify and hold Storywise, and its affiliates,
          and each of its and their respective officers, directors, agents,
          co-branders, other partners, and employees, harmless from any and all
          damage (whether direct, indirect, incidental, consequential or
          otherwise), loss, liability, cost and expense (including, without
          limitation, reasonable attorneys’ and accounting fees) resulting from
          any claim, demand, suit, proceeding (whether before an arbitrator,
          court, mediator or otherwise), or investigation made by any third
          party (each a “Claim”) due to or arising out of your use of,
          contribution to or connection with the platform; your violation of
          these Terms and/or your violation of any rights of a third party
          including infringement of Intellectual Property Rights of a third
          party. Storywise shall provide you notice of any such Claim; provided,
          that, the failure or delay by Storywise in providing such notice shall
          not limit your obligations hereunder. Storywise reserves the right to
          assume the exclusive defense and control of any matter which is
          subject to indemnification under this section 17, and in such case,
          you agree to cooperate with all reasonable requests of Storywise in
          assisting Storywise’s defense of such matter.
        </p>
      ),
    },
    {
      question: `18. Removal Requests`,
      answer: (
        <p>
          If Storywise receives a removal request related to your AI Content
          (e.g. for infringing a third party’s Intellectual Property Rights),
          Storywise may, at its sole discretion, taking into account
          technological limitation and the resources involved in carrying out
          such removal request, make its best efforts to remove such AI Content
          in whole or in part from the AI Functions, and will provide you with a
          prior notice of the actions taken in response to such request.
          Storywise shall have no obligation to investigate such a claim, and
          shall have no liability in relation to its compliance, non-compliance,
          or partial compliance with any such request. Storywise reserves the
          right to notify the person or entity which made the removal request
          providing the infringement notice of such removal at its discretion.
        </p>
      ),
    },
    {
      question: `19. Internal Use`,
      answer: (
        <p>
          Subject to the provisions in Company’s Privacy Policy, the Company may
          use information that it collects related to your use of the Platform (
          <b>“Usage Data”</b>) for its own internal usage, which may include
          without limitation, for quality control, security monitoring and
          product development. In addition, the Company may disclose Usage Data
          to third parties, provided that such information does not identify
          you, any personal Data that you transmitted to Storywise and/or any of
          your Confidential Information. Company retains all intellectual
          property rights and property rights in the Usage Data.
        </p>
      ),
    },
    {
      question: `20. Additional Terms`,
      answer: (
        <div>
          <p>
            <strong>20.1</strong> These Terms and the Agreement constitute the
            entire agreement between you and the Company and govern your use of
            the Services, superseding any prior or contemporaneous agreements,
            proposals, discussions or communications between you and the Company
            on the subject matter hereof.
          </p>

          <p>
            <strong>20.2</strong> These Terms and the Agreement are governed by
            the laws of England and Wales (including any dispute relating to the
            formation or applicability of the Agreement).
          </p>

          <p>
            <strong>20.3</strong> The failure or delay by the Company to
            exercise or enforce any right or provision of these Terms shall not
            constitute a waiver of such right or provision. No oral waiver,
            amendment or modification shall be effective under any circumstance
            whatsoever. If any provision of these Terms is found by an
            arbitrator or court of competent jurisdiction to be invalid, the
            parties nevertheless agree that the court should endeavor to give
            effect to the parties’ intentions as reflected in the provision, and
            the other provisions of these Terms shall remain in full force and
            effect.
          </p>

          <p>
            <strong>20.4</strong> The section titles in these Terms are for
            convenience only and have no legal or contractual effect.
          </p>

          <p>
            <strong>20.5</strong> You may not assign any of your rights or
            obligations hereunder, whether by operation of law or otherwise,
            without the Company’s prior written consent.
          </p>

          <p>
            <strong>20.6</strong> If any provision (or part of a provision) of
            these Terms is found by any court or administrative body of
            competent jurisdiction to be invalid, unenforceable or illegal, the
            other provisions (or the remainder of the provision) shall remain in
            full force and effect.
          </p>

          <p>
            <strong>20.7</strong> Except for any payment obligations, neither
            party will be responsible for any failure or delay in its
            performance under these Terms due to a force majeure event.
          </p>
        </div>
      ),
    },
    {
      question: `Last Updated: June 2024`,
      answer: ``,
    },
  ];

  const title = "Storywise AI Ltd. Terms and Conditions";

  return <FooterPatern title={title} Data={termsData} />;
};

export default Terms;
