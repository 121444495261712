import styles from "./UncoverLeft.module.css";
import leftImage from "../images/Frameuncover.svg";
import logo from "../images/logo1.svg";
import { useNavigate } from "react-router-dom";

const UncoverLeft = ({ children }) => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  // create a component that render the logo and the text: Uncover the Undiscovered and than an image
  return (
    <div className={styles.container}>
      {/* <div className={styles.left}> */}
      <div className={styles.logo} onClick={navigateHome}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.text}>
        {/* Uncover the Undiscovered */}
        Bringing Science to the Art of Storytelling
      </div>
      {/* </div> */}
      <div className={styles.imageLeft}>
        <img src={leftImage} alt="imageLeft" />
      </div>
    </div>
  );
};

export default UncoverLeft;
