import React from "react";
import styles from "./FooterPatern.module.css";
import Footer from "../landingP/Footer";
import UncoverLeft from "../../UI/UncoverLeft";
import TopCover from "../../UI/TopCover";
import arrow from "../../images/vuesax/linear/arrow-down.svg";
import { useNavigate } from "react-router-dom";
const FooterPatern = (props) => {
  const navigate = useNavigate();

  const arrowClick = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.UncoverLeft}>
        <UncoverLeft />
      </div>
      <div className={styles.logocontainer}>
        <div className={styles.TopCover}>
          <TopCover />
        </div>
        <div className={styles.FAQTEXT}>
          <div className={styles.arrow} onClick={arrowClick}>
            <img src={arrow} alt="arrow" className={styles.arrowImage} />
          </div>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.faqList}>
            {props.Data.map((item, index) => (
              <div className={styles.faqItem} key={index}>
                <div className={styles.question}>{item.question}</div>
                <div className={styles.answer}>{item.answer}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FooterPatern;
