import React from "react";
import styles from "./HowItWorks.module.css";
import step1 from "../../images/step1.png";
import step2 from "../../images/step2.png";
import step3 from "../../images/step3.png";
import allPart from "../../images/allpart2.svg";

export default function HowItWorksAuthors() {
  return (
    <div className={styles.AboutUs}>
      <div className={styles.mainLayout}>
        <div className={styles.title}>How the system works</div>
        <div className={styles.stepPanelsall}>
          <img
            src={allPart}
            alt="example"
            className={`${styles.svgIMG} ${styles.svgIMGAuthors}`}
          />
          <div className={styles.stepPanelsImg}>
            <img src={step1} alt="example" className={styles.svgIMGin} />
            <img src={step2} alt="example" className={styles.svgIMGin} />
            <img src={step3} alt="example" className={styles.svgIMGin} />
          </div>
        </div>
        <div className={styles.stepPanels}>
          <div className={styles.stepPanel}>
            <div className={styles.stepPanelstepPanelstext}>
              Generate a detailed recommendation report. Perfect your book with
              your personal 24/7 developmental editor.
            </div>
            <img
              src={step1}
              alt="example"
              className={`${styles.svgIMG} ${styles.svgIMGAuthors}`}
            />
          </div>
          <div className={styles.stepPanel}>
            <div className={styles.stepPanelstepPanelstext}>
              Develop great characters that will move your readers. Build your
              world easily and effectively.
            </div>

            <img
              src={step2}
              alt="example"
              className={`${styles.svgIMG} ${styles.svgIMGAuthors}`}
            />
          </div>
          <div className={styles.stepPanel}>
            <div className={styles.stepPanelstepPanelstext}>
              Fact check your book like a world class historian/
              navigator/private investigator/psychologist...
            </div>
            <img
              src={step3}
              alt="example"
              className={`${styles.svgIMG} ${styles.svgIMGAuthors}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
