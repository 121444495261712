import styles from "./AboutPage.module.css";

import Platformfeatures from "../landingP/PlatformfeaturesPublishers";
import AboutUs from "./AboutUs";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ContactUs from "../landingP/ContactUs";
import Footer from "../landingP/Footer";
import { useState } from "react";

const AboutPage = (props) => {
  const [alertType, setAlertType] = useState("success");

  const [showSuccess, setShowSuccess] = useState(false);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  return (
    <div className={styles.AboutPage}>
      {showSuccess && (
        <div
          className={`${styles.successMessage} ${styles.successMessageLeft}`}
        >
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertType === "error" && (
              <Alert severity="error">
                Please fill in all the required details
              </Alert>
            )}
            {alertType === "success" && (
              <Alert severity="success">
                {" "}
                Your message has been sent successfully!
              </Alert>
            )}
          </Stack>
        </div>
      )}
      <AboutUs />

      <ContactUs
        showSuccessMessage={showSuccessMessage}
        setAlertType={setAlertType}
      />
      <Footer />
    </div>
  );
};

export default AboutPage;
