import styles from "./AboutUs.module.css";
import logo from "../../images/logo.svg";
import Illustartionn from "../../images/Illustartion.png";
import manu from "../../images/manu.svg";
import { useNavigate } from "react-router-dom";
import out from "../../images/out.svg";
import React, { useState, useEffect, useRef } from "react";

const AboutUs = (props) => {
  const [manuOpen, setManuOpen] = useState(false);
  const navigate = useNavigate();

  const menuRef = useRef(null); // Ref for the menu

  // Function to close menu
  const closeMenu = () => setManuOpen(false);

  // Handle outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const navigateAboutus = () => {
    navigate("/AboutPage");
  };

  const navigateHome = () => {
    navigate("/");
  };

  const handelmanu = () => {
    setManuOpen(!manuOpen);
  };
  return (
    <div className={styles.AboutUs}>
      <div className={styles.header}>
        <div className={styles.logo} onClick={navigateHome}>
          <img src={logo} alt="logo" className={styles.logoImage} />
          <div className={styles.logoText}>Storywise</div>
        </div>
        <div className={styles.buttonsheader}>
          <div className={styles.button1} onClick={navigateAboutus}>
            <div className={styles.button1Text}>About us</div>
          </div>
          <a href="https://platform.storywise.ai/" className={styles.button2}>
            <div className={styles.button2Text}>Log in</div>
          </a>
          <a
            href="https://calendly.com/storywiseai/meet-with-gavin"
            className={styles.button3}
          >
            <div className={styles.button3Text}>Sign up</div>
          </a>
        </div>
      </div>
      <div className={styles.headermobile}>
        <div className={styles.logo} onClick={navigateHome}>
          <img src={logo} alt="logo" className={styles.logoImage} />
          <div className={styles.logoText}>Storywise</div>
        </div>
        <div className={styles.manu} onClick={handelmanu}>
          <img src={manu} alt="manu" className={styles.manuImage} />
        </div>
      </div>
      {manuOpen && (
        <div ref={menuRef} className={styles.manuOpen}>
          <div className={styles.headermobile}>
            <div className={styles.logo} onClick={navigateHome}>
              <img src={logo} alt="logo" className={styles.logoImage} />
              <div className={styles.logoText}>Storywise</div>
            </div>
            <div className={styles.manu} onClick={closeMenu}>
              <img src={out} alt="close" className={styles.manuImage} />
            </div>
          </div>
          <div className={styles.manuOpenText1} onClick={navigateAboutus}>
            About us
          </div>
          <a href="https://platform.storywise.ai/" className={styles.button2}>
            <div className={styles.button2Text}>Log in</div>
          </a>
          <a
            href="https://calendly.com/storywiseai/meet-with-gavin"
            className={styles.button3}
          >
            <div className={styles.button3Text}>Sign up</div>
          </a>
        </div>
      )}

      <div className={styles.firstSection}>
        <div className={styles.firstSectionTitle}>
          <div className={styles.firstSectionTitle1}>Our team</div>
          <div className={styles.firstSectionTitle2}>
            We’re a small small, bookish group with many years` experience in
            publishing, book distribution, authoring and technology. You are the
            creators and we are totally committed to freeing your time up to
            work on the creative bits of your job.{" "}
          </div>
        </div>
        <div className={styles.firstSectionImage}>
          <img
            src={Illustartionn}
            alt="Illustration"
            className={styles.Illustration}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
