import styles from "./PrivacyPolicy.module.css";
import FooterPatern from "../footerPattern/FooterPatern";

const PrivacyPolicy = () => {
  const privacyData = [
    {
      question: `Introduction`,
      answer: (
        <p className={styles.paragraph}>
          Storywise AI Limited ("we," "us," "our") holds the privacy of our
          users in the highest regard. This Privacy Policy governs our practices
          regarding the collection, use, and safeguarding of your information
          through our SaaS platform. We are dedicated to transparency and
          committed to operating under the data protection laws and regulations.
        </p>
      ),
    },
    {
      question: `The Information We Collect
            `,
      answer: (
        <div>
          <p>We collect and process the following information:</p>
          <ol className={styles.list}>
            <li className={styles.listItem}>
              Manuscript Submission Data: This pertains to the manuscripts and
              related metadata (e.g., submission date, author's name, email
              address).
            </li>
            <li className={styles.listItem}>
              Analysis Data: Data derived from our AI system's analysis and
              grading of the manuscripts.
            </li>
            <li className={styles.listItem}>
              Author Profile Data: Publicly available information concerning the
              author.
            </li>
            <li className={styles.listItem}>
              Publisher Data: Details about literary publishers and agents using
              our services, including contact information, service preferences,
              and usage data
            </li>
          </ol>
        </div>
      ),
    },
    {
      question: `Usage of Collected Information`,
      answer: (
        <div>
          <p>We use the data we collect for the following purposes:</p>
          <ol className={styles.list}>
            <li className={styles.listItem}>
              Service Provision: To offer, maintain, enhance, and improve our
              services.
            </li>
            <li className={styles.listItem}>
              Analysis and Decision Support: To analyze manuscript submissions,
              build author profiles, and assist literary publishers and agents
              in their decision-making processes.
            </li>
            <li className={styles.listItem}>
              Communication: To facilitate communication with publishers,
              respond to queries, and address any complaints.
            </li>
            <li className={styles.listItem}>
              Marketing: We may use the author information and insights derived
              from our platform to market our own services to authors in the
              future.
            </li>
            <li className={styles.listItem}>
              Research and Development: To evolve our offerings and develop new
              services based on user feedback and usage patterns.
            </li>
          </ol>
        </div>
      ),
    },
    {
      question: `Information Sharing and Security`,
      answer: (
        <div>
          <p>
            We pledge not to sell any information from authors or their
            submissions or provide such information for marketing purposes by
            other third-party services. We use APIs to search for similar books
            that have been published in the last ten years via a book
            categorization database.{" "}
          </p>
          <p>
            Moreover, the manuscript submissions and author information are not
            used to train our algorithms. We respect and value the intellectual
            property rights of authors, and we're committed to preventing
            unauthorized access to or use of such information.{" "}
          </p>
          <p>
            {" "}
            Our technical and organizational security measures aim to protect
            personal data from accidental or unlawful destruction, loss,
            alteration, unauthorized disclosure, or access.{" "}
          </p>
        </div>
      ),
    },
    {
      question: `Your Rights`,
      answer: (
        <div>
          <p>
            You have certain rights regarding your personal data, subject to
            local data protection laws. These may include the right to access,
            correct, delete, restrict processing, or object to processing. To
            exercise these rights, please contact us at {` `}
            <a href="mailto:contact@storywise.ai" className={styles.link}>
              contact@storywise.ai
            </a>
          </p>
        </div>
      ),
    },
    {
      question: `Privacy Policy Updates`,
      answer: `We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes, we will notify you by means of a notice on our platforms prior to the change becoming effective.
            `,
    },
    {
      question: `Contacting Us`,
      answer: (
        <div>
          <p>
            If you have questions, concerns, or suggestions regarding this
            Privacy Policy, please feel free to contact us at {` `}
            <a href="mailto:contact@storywise.ai" className={styles.link}>
              contact@storywise.ai
            </a>
          </p>
        </div>
      ),
    },
    {
      question: `Date Of Last Update`,
      answer: `2021-08-01`,
    },
  ];

  const title = "Privacy Policy";

  return <FooterPatern title={title} Data={privacyData} />;
};

export default PrivacyPolicy;
