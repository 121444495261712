import React from "react";
import styles from "./HowItWorks.module.css";
import step1 from "../../images/Loginstep1.svg";
import step2 from "../../images/Loginstep2.svg";
import step3 from "../../images/demostep3.svg";
import allPart from "../../images/allpart.svg";

export default function HowItWorksPublishers() {
  return (
    <div className={styles.AboutUs}>
      <div className={styles.mainLayout}>
        <div className={styles.title}>How the system works</div>
        <div className={styles.stepPanelsall}>
          <img src={allPart} alt="example" className={styles.svgIMG} />
          <div className={styles.stepPanelsImg}>
            <img
              src={step1}
              alt="example"
              className={`${styles.svgIMGin} ${styles.svgIMGinPublishers}`}
            />
            <img
              src={step2}
              alt="example"
              className={`${styles.svgIMGin} ${styles.svgIMGinPublishers}`}
            />
            <img
              src={step3}
              alt="example"
              className={`${styles.svgIMGin} ${styles.svgIMGinPublishers}`}
            />
          </div>
        </div>
        <div className={styles.stepPanels}>
          <div className={styles.stepPanel}>
            <div className={styles.stepPanelstepPanelstext}>
              Set up your preferences. Select the genres you like and the type
              of analysis you want us to do.
            </div>
            <img src={step1} alt="example" className={styles.svgIMG} />
          </div>
          <div className={styles.stepPanel}>
            <div className={styles.stepPanelstepPanelstext}>
              Connect your email submissions address to your account in our
              platform or drag and drop new submissions one at a time.
            </div>

            <img src={step2} alt="example" className={styles.svgIMG} />
          </div>
          <div className={styles.stepPanel}>
            <div className={styles.stepPanelstepPanelstext}>
              Our proprietary technology analyzes the manuscript and highlights
              the submissions you should take a second look at, together with a
              handy report card.
            </div>
            <img src={step3} alt="example" className={styles.svgIMG} />
          </div>
        </div>
      </div>
    </div>
  );
}
