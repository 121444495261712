import logo from './logo.svg';
import './App.css';
import './global.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/landingP/LandingPage';
import FQA from './components/pageQA/FAQ.js';
import AboutPage from './components/aboutUs/AboutPage';
import Terms from './components/terms/Terms';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';

function App() {

  return (
    <Router>
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/FAQ" element={<FQA />} />
        <Route path="/AboutPage" element={<AboutPage />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
    </Routes>
    </div>
    </Router>
  
  );
}

export default App;
