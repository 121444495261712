import backA from "../../images/authorsdesktop.png";
import logo from "../../images/logo.svg";
import allP from "../../images/publishersdesktop.png";
import PMobile from "../../images/publishermobile.svg";
import backAMobile from "../../images/authormobile.svg";
import styles from "./WalcomePart.module.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import out from "../../images/out.svg";
import manu from "../../images/manu.svg";
import { publisherOrAuthor } from "../../state";
import { useRecoilState } from "recoil";

const WalcomePart = (props) => {
  const [manuOpen, setManuOpen] = useState(false);
  const navigate = useNavigate();

  const menuRef = useRef(null); // Ref for the menu

  // Function to close menu
  const closeMenu = () => setManuOpen(false);

  const [option, setOption] = useRecoilState(publisherOrAuthor);

  // Handle outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const navigateAboutus = () => {
    navigate("/AboutPage");
  };

  const navigateHome = () => {
    navigate("/");
  };

  const swichOption = () => {
    setOption(option === "publishers" ? "authors" : "publishers");
    console.log(option);
  };

  const handelmanu = () => {
    setManuOpen(!manuOpen);
  };

  return (
    <div className={styles.WalcomePart}>
      <div className={styles.header}>
        <div className={styles.logo} onClick={navigateHome}>
          <img src={logo} alt="logo" className={styles.logoImage} />
          <div className={styles.logoText}>Storywise</div>
        </div>
        <div className={styles.buttonsheader}>
          <div className={styles.button1} onClick={navigateAboutus}>
            <div className={styles.button1Text}>About us</div>
          </div>
          <a href="https://platform.storywise.ai/" className={styles.button2}>
            <div className={styles.button2Text}>Log in</div>
          </a>
          <a
            href="https://calendly.com/storywiseai/meet-with-gavin"
            className={styles.button3}
          >
            <div className={styles.button3Text}>Sign up</div>
          </a>
        </div>
      </div>
      <div className={styles.headermobile}>
        <div className={styles.logo} onClick={navigateHome}>
          <img src={logo} alt="logo" className={styles.logoImage} />
          <div className={styles.logoText}>Storywise</div>
        </div>
        <div className={styles.manu} onClick={handelmanu}>
          <img src={manu} alt="manu" className={styles.manuImage} />
        </div>
      </div>
      {manuOpen && (
        <div ref={menuRef} className={styles.manuOpen}>
          <div className={styles.headermobile}>
            <div className={styles.logo} onClick={navigateHome}>
              <img src={logo} alt="logo" className={styles.logoImage} />
              <div className={styles.logoText}>Storywise</div>
            </div>
            <div className={styles.manu} onClick={closeMenu}>
              <img src={out} alt="close" className={styles.manuImage} />
            </div>
          </div>
          <div className={styles.manuOpenText1} onClick={navigateAboutus}>
            About us
          </div>
          <a href="https://platform.storywise.ai/" className={styles.button2}>
            <div className={styles.button2Text}>Log in</div>
          </a>
          <a
            href="https://calendly.com/storywiseai/meet-with-gavin"
            className={styles.button3}
          >
            <div className={styles.button3Text}>Sign up</div>
          </a>
        </div>
      )}
      <div className={styles.navBar}>
        {option === "authors" && (
          <div className={styles.optionBar}>
            <div className={styles.optionSelectedauthorstext}>For Authors</div>
            <div
              className={styles.optionNotSelectedPublisherText}
              onClick={swichOption}
            >
              For Publishers & Agents
            </div>
          </div>
        )}

        {option === "publishers" && (
          <div className={styles.optionBar}>
            <div
              className={styles.optionNotSelectedPublisherText}
              onClick={swichOption}
            >
              For Authors
            </div>
            <div className={styles.optionSelectedauthorstext}>
              For Publishers & Agents
            </div>
          </div>
        )}
      </div>
      <div className={styles.accaptLogo}>
        {option === "publishers" ? (
          <>
            <div className={styles.WalcomePartText}>
              <div className={styles.WalcomePartTextallTitle}>
                <div className={styles.WalcomePartText1}>
                  Technology that turns your slushpile
                </div>
                <div className={styles.WalcomePartText2}>into money</div>
              </div>
              <div className={styles.WalcomePartTextallText1}>Sign up now!</div>
              <a
                href="https://calendly.com/storywiseai/meet-with-gavin"
                target="_blank"
                className={styles.button3inside}
              >
                <div className={styles.button3Text}>Book a demo</div>
              </a>
              <div className={styles.note}>*No payment info required</div>
            </div>

            <div className={styles.mobilebookabook}>
              <div className={styles.WalcomePartTextallText1mobile}>
                Sign up now!
              </div>
              <a
                href="https://calendly.com/storywiseai/meet-with-gavin"
                target="_blank"
                className={styles.button3mobile}
              >
                <div className={styles.button3Text}>Book a demo</div>
              </a>
              <div className={styles.notemobile}>*No payment info required</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.WalcomePartText}>
              <div className={styles.WalcomePartTextallTitle}>
                <div className={styles.WalcomePartText2}>Get discovered.</div>
                <div className={styles.WalcomePartText1}>
                  Publish stories that sell.
                </div>
              </div>
              <div className={styles.WalcomePartTextallText1}>
                Try it for free!
              </div>
              <a
                href="https://tally.so/r/w8KlYl"
                target="_blank"
                className={styles.button3inside}
              >
                <div className={styles.button3Text}>Register for the Beta</div>
              </a>
              <div className={styles.note}>*No payment info required</div>
            </div>

            <div className={styles.mobilebookabook}>
              <div className={styles.WalcomePartTextallText1mobile}>
                Try it for free!
              </div>
              <a
                href="https://tally.so/r/w8KlYl"
                target="_blank"
                className={styles.button3mobile}
              >
                <div className={styles.button3Text}>Register for the Beta</div>
              </a>
              <div className={styles.notemobile}>*No payment info required</div>
            </div>
          </>
        )}

        <div className={styles.navBarinside}>
          {option === "publishers" && (
            <div className={styles.optionBar}>
              <div
                className={styles.optionNotSelectedPublisherText}
                onClick={swichOption}
              >
                For Authors
              </div>
              <div className={styles.optionSelectedauthorstext}>
                For Publishers & Agents
              </div>
            </div>
          )}
          {option === "authors" && (
            <div className={styles.optionBar}>
              <div className={styles.optionSelectedauthorstext}>
                For Authors
              </div>
              <div
                className={styles.optionNotSelectedPublisherText}
                onClick={swichOption}
              >
                For Publishers & Agents
              </div>
            </div>
          )}
        </div>
        <div className={styles.navBar_Image}>
          {option === "publishers" && (
            <img
              src={window.innerWidth <= 600 ? allP : allP}
              alt="allP"
              className={styles.Image}
            />
          )}
          {option === "authors" && (
            <img
              src={window.innerWidth <= 600 ? backA : backA}
              alt="backA"
              className={styles.Image2}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WalcomePart;
