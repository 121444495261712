import React from "react";
import styles from "./FAQ.module.css";
import Footer from "../landingP/Footer";
import FooterPatern from "../footerPattern/FooterPatern";
const FAQ = () => {
  const faqData = [
    {
      question: `Will my writing be used to train AI models?`,
      answer: `No, your writing will not be used to train AI models.
       Our primary objective is to analyze and provide feedback on your manuscript.
        All samples of your writing submitted to our Natural Language Processing models 
        for analysis are processed and then discarded without being stored in the model for
         future use. While we do retain copies of manuscripts for a period, they are strictly for 
         the purpose of ensuring the accuracy and quality of our feedback, and not for any training
          purposes.`,
    },
    {
      question: `Is my intellectual property at risk?`,
      answer: `Your intellectual property remains safeguarded. Access to your submitted manuscripts is strictly limited to our company and the publisher reviewing your manuscript. Any retained results from our review for future statistical analysis are fully anonymized, ensuring they cannot be traced back to individual authors or their works. Both authors and publishers can request the deletion of manuscripts at any point, further emphasizing our commitment to safeguarding your work. Rest assured that your creativity remains uniquely yours.`,
    },
    {
      question: `Is there a chance that somebody will be able to copy my book?`,
      answer: `No. Manuscripts uploaded to our platform are securely stored and are only accessible to our company and the specific publisher using our service to review your work. We adhere to stringent data handling and security measures to ensure that unauthorized parties cannot access your work.`,
    },
    {
      question: `How does your analysis work`,
      answer: `Our analysis involves comparing the uploaded manuscript to an anonymized model of similar books and sales patterns. The comparison assesses various elements of the content for eligibility, professionalism, and marketability. This comprehensive approach helps provide feedback based on established standards and trends in successful literature.`,
    },
    {
      question: `Where do you store submissions that you have analyzed?`,
      answer: `We store copies of analyzed manuscripts on our secure servers. Access to these manuscripts is restricted to our company and the specific publisher making use of our services for manuscript review. Both authors and publishers have the right to request deletion of these manuscripts, and we act promptly on such requests to ensure data privacy and security.`,
    },
    {
      question: `Can my writing be plagiarized?`,
      answer: `Given our robust security measures and restricted access, the risk of plagiarism from our platform is virtually non-existent. Your manuscript is only accessible to our company and, post-review, to editors at the designated publishing house if they wish to read it. We uphold the highest standards of data protection to ensure that unauthorized parties cannot access your work, thus minimizing any potential risk of plagiarism.`,
    },
    {
      question: `What are the sources of your training data?`,
      answer: `Our services are developed using (1) information that is publicly available on the internet, (2) information that we license from third parties, and (3) information that our users or human trainers provide. Your creative writing and all content that we generate from your creative writing e.g., a logline and synopsis, are explicitly excluded from current and future training data.`,
    },
    //  {
    //     question: `Have you unlawfully used copyrighted material without author consent?`,
    //     answer: `We have never knowingly used copyrighted material unlawfully and without the author’s consent. We acknowledge that there are court cases underway where authors have brough claims against OpenAI and Microsoft alleging that their copyrighted material was unlawfully exploited for gain, and we have taken explicit steps to prevent any creative material submitted on our platform from being used as training material for an AI model.`,
    // },
  ];

  const title = "Storywise FAQs";

  return <FooterPatern title={title} Data={faqData} />;
};

export default FAQ;
