// YourComponent.js
import React from "react";
import styles from "./Platformfeatures.module.css";
import Element1 from "../../images/Element1.svg";
import Element2 from "../../images/Element2.svg";
import Element3 from "../../images/Element3.svg";
import Element4 from "../../images/Element4.svg";
import Element5 from "../../images/Element5.svg";
import Element6 from "../../images/Element6.svg";

function PlatformfeaturesAuthors() {
  return (
    <div id="NewRootRoot" className={styles.bgContainer}>
      <div className={styles.title}>Platform features</div>
      <div className={styles.columnsinrow}>
        <div className={styles.onecol}>
          <div className={styles.Element1}>
            <div className={styles.Element1Image}>
              <img
                src={Element1}
                alt="Element1"
                className={styles.Element1Image}
              />
            </div>
            <div className={styles.Element1Text}>
              Detailed, customizable developmental editing report
            </div>
          </div>
          <div className={styles.Element1}>
            <div>
              <img
                src={Element4}
                alt="Element4"
                className={styles.Element1Image}
              />
            </div>
            <div className={styles.Element1Text}>
              Character development and world building tools{" "}
            </div>
          </div>
        </div>
        <div className={styles.onecol}>
          <div className={styles.Element1}>
            <div className={styles.Element1Image}>
              <img
                src={Element2}
                alt="Element2"
                className={styles.Element1Image}
              />
            </div>
            <div className={styles.Element1Text}>
              List of relevant and current comparatives
            </div>
          </div>
          <div className={styles.Element1}>
            <div className={styles.Element1Image}>
              <img
                src={Element5}
                alt="Element5"
                className={styles.Element1Image}
              />
            </div>
            <div className={styles.Element1Text}>
              Self publishing resources{" "}
            </div>
          </div>
        </div>
        <div className={styles.onecol}>
          <div className={styles.Element1}>
            <div className={styles.Element1Image}>
              <img
                src={Element3}
                alt="Element3"
                className={styles.Element1Image}
              />
            </div>
            <div className={styles.Element1Text}>Book cover designs</div>
          </div>

          <div className={styles.Element1}>
            <div className={styles.Element1Image}>
              <img
                src={Element6}
                alt="Element6"
                className={styles.Element1Image}
              />
            </div>
            <div className={styles.Element1Text}>And more…</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlatformfeaturesAuthors;
